import React from 'react'
import classNames from 'classnames'

const PickerButton = ({ copy, alt, selected, onClick }) => (
  <div
    className={classNames('x-components-asset-page-graph__time-selector', {
      'x-components-asset-page-graph__time-selector--selected': selected,
    })}
  >
    <a href="#" onClick={onClick} title={alt}>
      {copy}
    </a>
  </div>
)

export default PickerButton
