// dependencies.
import React from 'react'

const SummaryUser = ({ asset, colors, alt }) => {
  const [start, stop] = colors.gradient
  const white = '#FFFFFF'

  if (!start || !stop) return null

  const wrapperStyles = {
    display: 'block',
    position: 'relative',
    width: 'fit-content',
  }

  const svgStyles = {
    display: 'block',
    height: '100%',
    width: '100%',
  }

  return (
    <span style={wrapperStyles}>
      <svg viewBox="0 0 70 120" fill="none" style={svgStyles}>
        <path
          opacity={0.25}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.4997 40.0591V37.5007C52.4933 31.7087 48.4683 26.8751 43.2119 26.3455L40.4676 20.3439C40.0778 19.7663 38.9897 19.9759 37.5133 20.5311L26.7094 24.7631C21.3608 27.176 17.7701 32.7695 17.4997 39.1071V40.0591C17.2069 39.8319 16.8999 39.7087 16.5912 39.7359C15.2787 39.8511 14.4308 42.4431 14.6996 45.5247C14.9621 48.5551 16.2094 50.9184 17.4997 50.8848V52.3408C17.4997 59.3424 24.7574 70.032 34.9997 70.032C45.2403 70.032 52.5012 59.3392 52.4997 52.3408V50.8848C53.7899 50.9184 55.034 48.5551 55.2981 45.5247C55.5669 42.4431 54.719 39.8511 53.4065 39.7359C53.0994 39.7087 52.7908 39.8319 52.4997 40.0591Z"
          fill="url(#gradient1)"
        />
        <path
          opacity={0.4}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.9079 41.7108C52.4988 41.7108 52.4988 39.6596 52.4988 39.6596V37.5012C52.494 31.7092 48.469 26.8756 43.2126 26.346L40.4683 20.3444C40.077 19.7668 38.9904 19.9764 37.514 20.5316L26.7101 24.7636C21.3615 27.1764 17.7692 32.7684 17.4988 39.1076V45.098C17.4988 45.098 21.212 39.7652 27.7076 39.498C34.2033 39.2308 35.6479 41.2036 41.4881 41.6836C43.8076 41.8756 47.4576 41.7108 49.9079 41.7108Z"
          fill="url(#gradient2)"
        />
        <path
          opacity={0.25}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.9993 58.4001C26.9993 58.4001 27.6865 64.032 34.9997 64.032C42.313 64.032 42.9993 58.4001 42.9993 58.4001"
          fill="url(#gradient3)"
        />
        <path
          d="M38.4258 100.4L60.8354 77.9905L64.9984 79.5041C68.0259 80.7105 70.0098 83.6577 70.0002 86.9345V100.4H57.9994V88.4001L54.9994 100.4H38.4258Z"
          fill={start}
        />
        <g opacity={0.4} style={{ mixBlendMode: 'overlay' }}>
          <path
            d="M38.4258 100.4L60.8354 77.9905L64.9984 79.5041C68.0259 80.7105 70.0098 83.6577 70.0002 86.9345V100.4H57.9994V88.4001L54.9994 100.4H38.4258Z"
            fill={white}
          />
        </g>
        <path
          d="M60.8353 77.9904L38.4258 100.4H38.4344L14.9992 100.4L14.3122 97.652L33.4068 78.5574C33.9237 78.5924 34.4547 78.6146 35 78.624C41.308 78.7344 45.648 76.9904 48.4305 73.48L60.8353 77.9904Z"
          fill={start}
        />
        <path d="M11.9992 100.4V99.965L11.5642 100.4H11.9992Z" fill={start} />
        <path
          d="M34.15 78.5984C28.4858 78.3542 24.4439 76.6523 21.7558 73.3999L5.00191 79.5039C1.97282 80.7103 -0.0110435 83.6575 4.62553e-05 86.9343V100.4H11.5643L11.9992 99.965V100.4L11.9993 88.3999L14.3123 97.6519L33.4068 78.5574C33.6513 78.574 33.8991 78.5876 34.15 78.5984Z"
          fill={stop}
        />
        <defs>
          <linearGradient
            id="gradient1"
            x1={21.5343}
            y1={20}
            x2={57.3873}
            y2={63.6438}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={white} stopOpacity={0.1} />
            <stop offset={1} stopColor={white} stopOpacity={0.5} />
          </linearGradient>
          <linearGradient
            id="gradient2"
            x1={53.1988}
            y1={39.1308}
            x2={24.1082}
            y2={18.4752}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={white} stopOpacity={0.1} />
            <stop offset={1} stopColor={white} stopOpacity={0.5} />
          </linearGradient>
          <linearGradient
            id="gradient3"
            x1={26.9993}
            y1={60.8672}
            x2={41.7705}
            y2={66.0155}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={white} stopOpacity={0.1} />
            <stop offset={1} stopColor={white} stopOpacity={0.5} />
          </linearGradient>
        </defs>
      </svg>
    </span>
  )
}

export default SummaryUser
