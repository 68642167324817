// dependencies.
import React, { forwardRef } from 'react'
import Img from 'gatsby-image'
// components.
import Carousel from 'src/components/carousel/AppsCarousel'
// utils.
import buildImageObject from 'src/js/utils/buildImgObject'

// Styles & Images:
import 'src/components/pages/asset-page/scss/Web3Section.scss'

// Main component:
const Web3Section = ({ page, data }, ref) => {
  const image = {
    aspectRatio: 1.521,
    path: `/asset-page/img/${page}/`,
    fileType: 'png',
    webp: true,
    files: [
      {
        fileName: 'web3-screens-lsize',
        width: 1600,
      },
    ],
  }

  const carousel = [
    {
      aspectRatio: 0.6,
      path: `/asset-page/img/${page}/`,
      fileType: 'jpg',
      webp: true,
      files: [
        {
          fileName: 'web3-screen1-msize',
          width: 1600,
        },
      ],
    },
    {
      aspectRatio: 0.6,
      path: `/asset-page/img/${page}/`,
      fileType: 'jpg',
      webp: true,
      files: [
        {
          fileName: 'web3-screen2-msize',
          width: 1600,
        },
      ],
    },
    {
      aspectRatio: 0.6,
      path: `/asset-page/img/${page}/`,
      fileType: 'jpg',
      webp: true,
      files: [
        {
          fileName: 'web3-screen3-msize',
          width: 1600,
        },
      ],
    },
  ]

  return (
    <section ref={ref} className="x__asset-page__web3">
      <div className="x__asset-page__web3__img-container">
        <div className="x__asset-page__web3__img">
          <Img fluid={buildImageObject(image)} durationFadeIn={1000} alt={data.h2} />
        </div>
      </div>

      <div className="x__asset-page__web3__content">
        <h2
          className="x__asset-page__web3__heading"
          dangerouslySetInnerHTML={{ __html: data.h2 }}
        />
        <p
          className="x__asset-page__web3__subheading"
          dangerouslySetInnerHTML={{ __html: data.copy }}
        />
      </div>

      <Carousel id="x__asset-page__web3__carousel">
        {carousel.map((item, i) => (
          <div key={i} className="x__asset-page__web3__carousel-img">
            <Img fluid={buildImageObject(item)} durationFadeIn={1000} alt={data.h2} />
          </div>
        ))}
      </Carousel>
    </section>
  )
}

export default forwardRef(Web3Section)
