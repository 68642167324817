const defaultContent = (asset) => {
  const { name, ticker, mobileWallet, desktopWallet, web3Wallet, exchange, trezor } = asset

  const headerContent = {
    defaultHeader: {
      h1: `Exodus<span class="x-break-sm"></span> ${name}<span class="x-break-sm"></span> Wallet`,
      h2: `Send, Receive, and Swap<span class="x-break-sm"></span> With Exodus' Free and<span class="x-break-sm"></span> Secure ${ticker} Wallet`,
    },
  }

  const aboutContent = {
    defaultAbout: {
      h2: `About ${name} (${ticker})`,
    },
  }

  const mobileContent = mobileWallet && {
    defaultMobile: {
      h2: `Exodus ${name}<span class="x-break-sm"></span> Mobile Wallet`,
      copy: `Secure, manage, and swap your ${ticker} with the mobile security of face or fingerprint scanning. Sync between the ${name} desktop wallet and mobile wallet to use your ${ticker} on multiple devices.`,
    },
  }

  const desktopContent = desktopWallet && {
    defaultDesktop: {
      h2: `Exodus ${name}<span class="x-break-sm"></span> Desktop Wallet`,
      copy: `Send and receive ${ticker} easily with a ${name} ${ticker} address or scannable QR code. Learn how to Send and Receive in Exodus.`,
    },
  }

  const web3Content = web3Wallet && {
    defaultWeb3: {
      h2: `Exodus ${name} Web3<span class="x-break-sm"></span> Browser Extension`,
      copy: `Connect your ${name} wallet to the world of of DeFi and Web3 on Chrome and Brave browsers. Manage and swap ${ticker} on multiple networks like Ethereum, Solana, Tron, Polygon, and many more.`,
    },
  }

  const hasSend = {
    send: {
      h2: `Send and Receive<br /> ${name} ${ticker}`,
      copy: `Send and receive ${ticker} easily with a ${name} ${ticker} address or scannable QR code. Learn how to send and receive in Exodus.`,
    },
  }

  const hasTrezor = trezor && {
    trezor: {
      h2: 'Trezor Hardware Wallet Integration',
      copy: `Combine the security of Trezor with the simplicity of Exodus for the ultimate ${name} wallet experience.`,
    },
  }

  const hasExchange = exchange && {
    swap: {
      h2: `Swap ${name} instantly from your wallet`,
      copy: `Easily exchange ${name} for your favorite cryptos like Ethereum, Monero, Litecoin, and more right from your wallet, in seconds. No sign up required. Learn how to swap in Exodus.`,
    },
  }

  const hasSecurity = {
    security: {
      h2: 'Private and Secure',
      copy: 'Exodus encrypts private keys and transaction data. On your device and for your eyes only. Your data remains private – no account setup or verification required.',
    },
  }

  const hasFriendly = {
    friendly: {
      h2: 'User-friendly',
      copy: "We've prepared extensive detailed guides and video tutorials for you. Whether you're a new or advanced user, our 24/7 Support Team is here to help.",
    },
  }

  const assetsContent = {
    h2: 'assets and tokens supported',
    count: 100000,
    cta: 'See the full list',
  }

  const downloadCopyWallets =
    desktopWallet && mobileWallet
      ? trezor
        ? 'Desktop,<span class="x-break-sm"></span> Mobile, and Trezor<span class="x-break-sm"></span>'
        : 'Desktop and<span class="x-break-sm"></span> Mobile'
      : desktopWallet && !mobileWallet
      ? trezor
        ? 'Desktop and<span class="x-break-sm"></span> Trezor'
        : 'Desktop<span class="x-break-sm"></span>'
      : !desktopWallet && mobileWallet
      ? trezor
        ? 'Mobile and<span class="x-break-sm"></span> Trezor'
        : 'Mobile<span class="x-break-sm"></span>'
      : ''
  const downloadCopyExchange = exchange
    ? 'Send, Receive,<span class="x-break-sm"></span> and Swap'
    : 'Send and Receive'

  const downloadContent = {
    h2: `Get Exodus for ${downloadCopyWallets} to ${downloadCopyExchange} ${name}.`,
  }

  return {
    ...headerContent,
    ...mobileContent,
    ...desktopContent,
    ...web3Content,
    ...aboutContent,

    defaultSummary: {
      ...hasSend,
      ...hasExchange,
      ...hasTrezor,
      ...hasSecurity,
      ...hasFriendly,
    },

    defaultAssets: {
      ...assetsContent,
    },

    defaultDownload: {
      ...downloadContent,
    },
  }
}

export default defaultContent
