// dependencies.
import React from 'react'

const SummarySend = ({ asset, colors, alt }) => {
  const [start, stop] = colors.gradient
  const black = '#FFFFFF'
  const white = '#FFFFFF'

  if (!start || !stop) return null

  const wrapperStyles = {
    display: 'block',
    position: 'relative',
    width: 'fit-content',
  }

  const svgStyles = {
    display: 'block',
    height: '100%',
    width: '100%',
  }

  return (
    <span style={wrapperStyles}>
      <svg viewBox="0 0 113 120" fill="none" style={svgStyles}>
        <title>{alt}</title>
        <path
          opacity={0.5}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M83.3977 41.5435L38.0671 65.4888L42.1753 92.5998L83.3977 41.5435Z"
          fill="url(#gradient1)"
        />
        <path
          opacity={0.5}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M90.3698 30.4341L42.1753 68.6483V92.5998L90.3698 30.4341Z"
          fill="url(#gradient2)"
        />
        <g opacity={0.25} filter="url(#filter1)">
          <path
            d="M22.7063 54.3856L90.3698 30.4341C90.3698 30.4341 80.9909 37.8707 62.2331 52.7441L37.8541 65.4826L22.7063 54.3856Z"
            fill={black}
          />
        </g>
        <path
          opacity={0.25}
          d="M22.7063 54.3856L90.3698 30.4341C90.3698 30.4341 80.9909 37.8707 62.2331 52.7441L37.8541 65.4826L22.7063 54.3856Z"
          fill="url(#gradient3)"
        />
        <path
          d="M54.4614 58.9065L42.1753 68.6484L75.7229 93.2249C76.2775 93.6311 77.0018 93.7241 77.641 93.4711C78.2801 93.2181 78.7446 92.6545 78.8708 91.9788L81.394 78.4742L54.4614 58.9065Z"
          fill={stop}
        />
        <path
          d="M64.5708 50.8906L83.921 64.9493L81.394 78.4742L54.4614 58.9066L64.5708 50.8906Z"
          fill={start}
        />
        <path d="M83.921 64.9493L64.5708 50.8905L90.3698 30.4341L83.921 64.9493Z" fill={start} />
        <g opacity={0.4} style={{ mixBlendMode: 'overlay' }}>
          <path d="M83.921 64.9493L64.5708 50.8905L90.3698 30.4341L83.921 64.9493Z" fill={white} />
        </g>
        <g opacity={0.4}>
          <path
            d="M104.169 26.4714L103.688 26.6412L103.355 25.6984L103.836 25.5286L104.169 26.4714Z"
            fill={white}
          />
          <path
            d="M99.6994 28.0506L98.7382 28.3902L98.4051 27.4473L99.3663 27.1077L99.6994 28.0506Z"
            fill={white}
          />
          <path
            d="M94.7493 29.7996L93.7881 30.1392L93.455 29.1963L94.4162 28.8567L94.7493 29.7996Z"
            fill={white}
          />
          <path
            d="M89.7992 31.5485L88.838 31.8881L88.5049 30.9452L89.466 30.6056L89.7992 31.5485Z"
            fill={white}
          />
          <path
            d="M84.8491 33.2975L83.8879 33.6371L83.5547 32.6942L84.5159 32.3546L84.8491 33.2975Z"
            fill={white}
          />
          <path
            d="M79.8989 35.0464L78.9378 35.386L78.6046 34.4432L79.5658 34.1036L79.8989 35.0464Z"
            fill={white}
          />
          <path
            d="M74.9488 36.7954L73.9876 37.135L73.6545 36.1921L74.6157 35.8525L74.9488 36.7954Z"
            fill={white}
          />
          <path
            d="M69.9987 38.5444L69.0375 38.884L68.7044 37.9411L69.6656 37.6015L69.9987 38.5444Z"
            fill={white}
          />
          <path
            d="M65.0486 40.2933L64.0874 40.6329L63.7543 39.69L64.7155 39.3504L65.0486 40.2933Z"
            fill={white}
          />
          <path
            d="M60.0985 42.0423L59.1373 42.3819L58.8042 41.439L59.7654 41.0994L60.0985 42.0423Z"
            fill={white}
          />
          <path
            d="M55.1484 43.7912L54.1872 44.1308L53.854 43.188L54.8152 42.8484L55.1484 43.7912Z"
            fill={white}
          />
          <path
            d="M50.1983 45.5402L49.2371 45.8798L48.9039 44.9369L49.8651 44.5973L50.1983 45.5402Z"
            fill={white}
          />
          <path
            d="M45.2481 47.2892L44.287 47.6288L43.9538 46.6859L44.915 46.3463L45.2481 47.2892Z"
            fill={white}
          />
          <path
            d="M40.298 49.0381L39.3368 49.3777L39.0037 48.4348L39.9649 48.0952L40.298 49.0381Z"
            fill={white}
          />
          <path
            d="M35.3479 50.7871L34.3867 51.1267L34.0536 50.1838L35.0148 49.8442L35.3479 50.7871Z"
            fill={white}
          />
          <path
            d="M30.3978 52.536L29.4366 52.8756L29.1035 51.9328L30.0647 51.5932L30.3978 52.536Z"
            fill={white}
          />
          <path
            d="M25.4477 54.285L24.4865 54.6246L24.1534 53.6817L25.1146 53.3421L25.4477 54.285Z"
            fill={white}
          />
          <path
            d="M20.4976 56.034L19.5364 56.3736L19.2032 55.4307L20.1644 55.0911L20.4976 56.034Z"
            fill={white}
          />
          <path
            d="M15.5475 57.7829L14.5863 58.1225L14.2531 57.1796L15.2143 56.84L15.5475 57.7829Z"
            fill={white}
          />
          <path
            d="M10.5973 59.5319L9.63615 59.8715L9.30302 58.9286L10.2642 58.589L10.5973 59.5319Z"
            fill={white}
          />
          <path
            d="M5.64722 61.2808L5.16663 61.4506L4.8335 60.5078L5.31409 60.338L5.64722 61.2808Z"
            fill={white}
          />
          <path
            d="M9.0638 44.1356L8.65312 43.8279L9.25277 43.0276L9.66345 43.3353L9.0638 44.1356Z"
            fill={white}
          />
          <path
            d="M13.2938 47.3051L12.4724 46.6897L13.0721 45.8894L13.8934 46.5049L13.2938 47.3051Z"
            fill={white}
          />
          <path
            d="M17.5237 50.4747L16.7024 49.8593L17.302 49.059L18.1234 49.6745L17.5237 50.4747Z"
            fill={white}
          />
          <path
            d="M21.7537 53.6443L20.9323 53.0288L21.532 52.2286L22.3533 52.844L21.7537 53.6443Z"
            fill={white}
          />
          <path
            d="M25.9837 56.8139L25.1623 56.1984L25.762 55.3981L26.5833 56.0136L25.9837 56.8139Z"
            fill={white}
          />
          <path
            d="M30.2136 59.9834L29.3923 59.368L29.9919 58.5677L30.8133 59.1832L30.2136 59.9834Z"
            fill={white}
          />
          <path
            d="M34.4436 63.153L33.6222 62.5375L34.2219 61.7373L35.0432 62.3527L34.4436 63.153Z"
            fill={white}
          />
          <path
            d="M38.2629 66.0148L37.8522 65.7071L38.4519 64.9068L38.8625 65.2146L38.2629 66.0148Z"
            fill={white}
          />
        </g>
        <defs>
          <filter
            id="filter1"
            x={0.706299}
            y={12.4341}
            width={111.664}
            height={79.0486}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy={4} />
            <feGaussianBlur stdDeviation={11} />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <linearGradient
            id="gradient1"
            x1={42.8657}
            y1={41.5435}
            x2={84.7402}
            y2={88.2448}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={white} stopOpacity={0.1} />
            <stop offset={1} stopColor={white} stopOpacity={0.5} />
          </linearGradient>
          <linearGradient
            id="gradient2"
            x1={51.7895}
            y1={30.4341}
            x2={93.4943}
            y2={83.7014}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={white} stopOpacity={0.1} />
            <stop offset={1} stopColor={white} stopOpacity={0.5} />
          </linearGradient>
          <linearGradient
            id="gradient3"
            x1={91.7231}
            y1={52.7542}
            x2={33.2242}
            y2={22.7499}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={white} stopOpacity={0.1} />
            <stop offset={1} stopColor={white} stopOpacity={0.5} />
          </linearGradient>
        </defs>
      </svg>
    </span>
  )
}

export default SummarySend
