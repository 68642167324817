// dependencies.
import React, { useEffect, useState } from 'react'
import elv from 'elv'
import { differenceInDays, sub } from 'date-fns'
import { withSize } from 'react-sizeme'
// components.
import PickerButton from 'src/components/assets/Chart/PickerButton'
// utils.
import { fetchHistoricalPrices } from 'src/js/assets/fetchPrice'
import { returnTitle, states } from 'src/components/assets/Chart/constants'

// Styles & Images
import 'static/components/graph/scss/styles.scss'

// F2 Modularized:
const F2 = require('@antv/f2/lib/core')
require('@antv/f2/lib/geom/line')
require('@antv/f2/lib/geom/area')
const Animation = require('@antv/f2/lib/animation/detail')
const Animate = require('@antv/f2/lib/animation/animate').default
const Tooltip = require('@antv/f2/lib/plugin/tooltip')

// Main component:
const AssetPageGraph = ({
  fromSymbol,
  toSymbol,
  colorStart,
  colorStop,
  size,
  showPicker = true,
  showYAxis = true,
  height = 230,
  topMargin = 60,
  rightMargin = 20,
  bottomMargin = 20,
  leftMargin = 50,
}) => {
  const [currentState, setCurrentState] = useState(states.oneWeek)
  const [currentScale, setCurrentScale] = useState({
    type: 'timeCat',
    mask: 'MMM, D',
    tickCount: 3,
  })
  const [data, setData] = useState([])
  const [dataCache, setDataCache] = useState({ hour: [], day: [] })
  const [showHourlyDataPickerButtons, setShowHourlyDataPickerButtons] = useState(true)

  let chart

  const loadData = () => {
    let dataCacheCopy

    if (currentState === states.oneDay) {
      dataCacheCopy = dataCache.hour.slice(-24)
    }

    if (currentState === states.oneWeek) {
      dataCacheCopy = dataCache.hour
    }

    const now = new Date()

    if (currentState === states.oneMonth) {
      dataCacheCopy = dataCache.day.slice(-differenceInDays(now, sub(now, { months: 1 })))
    }

    if (currentState === states.threeMonths) {
      dataCacheCopy = dataCache.day.slice(-differenceInDays(now, sub(now, { months: 3 })))
    }

    if (currentState === states.sixMonths) {
      dataCacheCopy = dataCache.day.slice(-differenceInDays(now, sub(now, { months: 6 })))
    }

    if (currentState === states.oneYear) {
      dataCacheCopy = dataCache.day.slice(-differenceInDays(now, sub(now, { years: 1 })))
    }

    if (currentState === states.twoYears) {
      dataCacheCopy = dataCache.day
    }

    if (elv(dataCacheCopy)) {
      setData(dataCacheCopy)
      if (elv(chart)) {
        chart.changeData(dataCacheCopy)
        chart.scale('date', currentScale)
      }
    }
  }

  const transformData = (records, cacheKey) => {
    const data = []

    for (const item of records) {
      const record = {}
      record.date = new Date(item.time * 1000)
      record.close = parseFloat(item.close)
      data.push(record)
    }

    setDataCache({ ...dataCache, [cacheKey]: data })

    if (cacheKey === 'hour' && !data.length) {
      setShowHourlyDataPickerButtons(false)
      setCurrentState(states.oneYear)
      setCurrentScale({ type: 'timeCat', tickCount: 7, mask: 'MMM, D', nice: true })
    }
  }

  const fetchData = async (granularity) => {
    try {
      if (dataCache[granularity].length === 0) {
        const prices = await fetchHistoricalPrices({ assets: [fromSymbol], granularity })
        if (!prices[fromSymbol]) return

        transformData(prices[fromSymbol].USD, granularity)
      }
      loadData()
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    fetchData('hour')
    fetchData('day')
  }, [dataCache, currentState])

  const handle1DClicked = (e) => {
    e.preventDefault()
    setCurrentState(states.oneDay)
    setCurrentScale({ type: 'timeCat', tickCount: 7, mask: 'MMM, D' })
  }

  const handle1WClicked = (e) => {
    e.preventDefault()
    setCurrentState(states.oneWeek)
    setCurrentScale({ type: 'timeCat', tickCount: 3, mask: 'MMM, D' })
  }

  const handle1MClicked = (e) => {
    e.preventDefault()
    setCurrentState(states.oneMonth)
    setCurrentScale({ type: 'timeCat', tickCount: 3, mask: 'MMM, D', nice: true })
  }

  const handle3MClicked = (e) => {
    e.preventDefault()
    setCurrentState(states.threeMonths)
    setCurrentScale({ type: 'timeCat', tickCount: 3, mask: 'MMM, D', nice: true })
  }

  const handle6MClicked = (e) => {
    e.preventDefault()
    setCurrentState(states.sixMonths)
    setCurrentScale({ type: 'timeCat', tickCount: 3, mask: 'MMM, D', nice: true })
  }

  const handle1YClicked = (e) => {
    e.preventDefault()
    setCurrentState(states.oneYear)
    setCurrentScale({ type: 'timeCat', tickCount: 7, mask: 'MMM, D', nice: true })
  }

  const handle2YClicked = (e) => {
    e.preventDefault()
    setCurrentState(states.twoYears)
    setCurrentScale({ type: 'timeCat', tickCount: 7, mask: 'MMM, D', nice: true })
  }

  const drawChart = () => {
    if (elv(chart)) chart.destroy()

    if (typeof document !== `undefined`) {
      Animate.registerAnimation('lineUpdate', (updateShape, animateCfg) => {
        const cacheShape = updateShape.get('cacheShape')
        const cacheAttrs = cacheShape.attrs
        const geomType = cacheShape.geomType

        const oldPoints = cacheAttrs.points
        const newPoints = updateShape.attr('points')

        const oldLength = oldPoints.length
        const newLength = newPoints.length
        let deltaLength = geomType === 'area' ? (oldLength - newLength) / 2 : oldLength - newLength

        if (deltaLength > 0) {
          const firstPoint = newPoints[0]
          const lastPoint = newPoints[newPoints.length - 1]

          for (let i = 0; i < deltaLength; i++) {
            newPoints.splice(0, 0, firstPoint)
          }

          if (geomType === 'area') {
            for (let j = 0; j < deltaLength; j++) {
              newPoints.push(lastPoint)
            }
          }
        } else {
          deltaLength = Math.abs(deltaLength)
          const firstPoint1 = oldPoints[0]
          const lastPoint1 = oldPoints[oldPoints.length - 1]

          for (let k = 0; k < deltaLength; k++) {
            oldPoints.splice(0, 0, firstPoint1)
          }

          if (geomType === 'area') {
            for (let p = 0; p < deltaLength; p++) {
              oldPoints.push(lastPoint1)
            }
          }

          cacheAttrs.points = oldPoints
        }
        updateShape.attr(cacheAttrs)
        updateShape.animate().to({
          attrs: { points: newPoints },
          duration: 300,
          easing: animateCfg.easing,
        })
      })

      chart = new F2.Chart({
        id: `x_chart`,
        height,
        pixelRatio: window.devicePixelRatio,
        padding: [topMargin, rightMargin, bottomMargin, leftMargin],
        plugins: [Tooltip, Animation],
      })

      const formatterCurrency = new Intl.NumberFormat(navigator.language, {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 5,
      })

      chart.appendPadding = 0
      chart.tooltip({
        triggerOn: ['touchstart', 'touchmove', 'mousemove'],
        showTitle: true,
        showItemMarker: false,
        titleStyle: {
          fill: '#6D6D6D',
          textAlign: 'center',
          display: 'block',
          fontSize: '13',
        },
        valueStyle: {
          fill: '#fff',
          textAlign: 'center',
          display: 'block',
          fontSize: '15',
        },
        tooltipMarkerStyle: {
          fill: '#1A1C1F',
          stroke: colorStart,
          strokeOpacity: 1,
          lineWidth: 2,
        },
        background: {
          radius: 4,
          fill: '#0D0D0D',
          outline: '#0D0D0D',
          shadowColor: 'rgba(0, 0, 0, 0.3)',
          stroke: '#0D0D0D',
          padding: [10, 10],
          lineWidth: 0,
        },
        onShow: (ev) => {
          const items = ev.items
          items[0].title = returnTitle(items[0].origin.date, currentState)
          items[0].name = null
          items[0].value = '   ' + formatterCurrency.format(items[0].value) + ' USD   '

          items.splice(1)
        },
        showCrosshairs: true,
        offsetY: 0,
        offsetX: 0,
        crosshairsStyle: { stroke: '#454545', lineWidth: 1 },
      })

      chart
        .line({ sortable: false })
        .position('date*close')
        .color(`l(0) 0:${colorStart} 1:${colorStop}`)
        .style({ lineWidth: 1.9 })
        .animate({ update: { animation: 'lineUpdate' } })

      chart
        .area({ sortable: false })
        .position('date*close')
        .color('l(90) 0:' + colorStart + ' 1:#1A1C1F')
        .animate({ update: { animation: 'lineUpdate' } })

      chart.scale('close', {
        tickCount: 6,
        formatter: function (val) {
          let returnVal = val.toFixed(2)
          if (val < 1) returnVal = val.toFixed(5)
          return returnVal
        },
      })

      chart.scale('date', currentScale)
      chart.axis('date', {
        labelOffset: 10,
        label: function label(text, index, total) {
          const config = {}
          if (index === 0) {
            config.textAlign = 'left'
          } else if (index === total - 1) {
            config.textAlign = 'right'
          }

          return config
        },
        line: {
          stroke: 'rgba(255,255,255,.08)',
          lineWidth: 1,
        },
      })

      chart.source(data)

      showYAxis
        ? chart.axis('close', {
            labelOffset: 5,
            grid: {
              type: 'line',
              top: true,
              stroke: 'rgba(255,255,255,.08)',
              lineWidth: 1,
              lineDash: [0],
            },
          })
        : chart.axis('close', {
            label: showYAxis,
            labelOffset: 5,
            grid: {
              type: 'line',
              top: true,
              stroke: 'rgba(255,255,255,.08)',
              lineWidth: 1,
              lineDash: [0],
            },
          })

      chart.render()
    }
  }

  const renderPicker = () => {
    return (
      <div className="x-components-asset-page-graph__time-selectors">
        {showHourlyDataPickerButtons && (
          <>
            <PickerButton
              copy={states.oneDay}
              alt="One Day"
              selected={currentState === states.oneDay}
              onClick={handle1DClicked}
            />
            <PickerButton
              copy={states.oneWeek}
              alt="One Week"
              selected={currentState === states.oneWeek}
              onClick={handle1WClicked}
            />
          </>
        )}
        <PickerButton
          copy={states.oneMonth}
          alt="One Month"
          selected={currentState === states.oneMonth}
          onClick={handle1MClicked}
        />
        <PickerButton
          copy={states.threeMonths}
          alt="Three Months"
          selected={currentState === states.threeMonths}
          onClick={handle3MClicked}
        />
        <PickerButton
          copy={states.sixMonths}
          alt="Six Months"
          selected={currentState === states.sixMonths}
          onClick={handle6MClicked}
        />
        <PickerButton
          copy={states.oneYear}
          alt="One Year"
          selected={currentState === states.oneYear}
          onClick={handle1YClicked}
        />
        <PickerButton
          copy={states.twoYears}
          alt="Two Years"
          selected={currentState === states.twoYears}
          onClick={handle2YClicked}
        />
      </div>
    )
  }

  const updateWindow = () => {
    if (!elv(size.width)) return null

    if (typeof document !== `undefined`) {
      const domE = document.getElementById(`x_chart`)

      if (elv(domE)) {
        const newWidth = Math.floor(size.width) * window.devicePixelRatio

        if (domE.width !== newWidth) {
          domE.width = newWidth
          domE.style.width = `${size.width}px`
          drawChart()
        }
      }
    }

    return null
  }

  useEffect(() => {
    drawChart()
  }, [data])

  useEffect(() => {
    updateWindow()
  }, [size])

  return (
    <div>
      <div className="mb-3">{showPicker && renderPicker()}</div>
      <canvas id="x_chart" />
    </div>
  )
}

export default withSize()(AssetPageGraph)
