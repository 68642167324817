// dependencies.
import React, { forwardRef } from 'react'
// components.
import AssetIcon from 'src/components/assets/shared/AssetIcon'
import PlatformDropdown, { platforms } from 'src/components/button/Download/PlatformDropdown'

// Styles & Images:
import 'src/components/pages/asset-page/scss/HeaderSection.scss'

// Main component:
const HeaderSection = ({ asset, colors, data, location }, ref) => {
  const checkColorsOverride =
    colors && colors.gradient && colors.gradient.length > 0
      ? colors.gradient
      : asset.colors.gradient

  // Download links.
  const DOWNLOAD_LINKS_ENABLED = asset.desktopWallet || asset.mobileWallet || asset.web3Wallet

  const downloadDropdownProps = {
    colors: checkColorsOverride,
    trackerPage: `${asset.name}Page`,
    location,
  }

  const googleDownloadProps = {
    utmSource: 'exodus-website',
    utmCampaign: `${asset.ticker.toLowerCase()}-wallet`,
    utmContent: `${asset.ticker.toLowerCase()}-wallet`,
  }

  return (
    <header ref={ref} className="x__asset-page__header">
      <div className="x__asset-page__header__content">
        <AssetIcon icon={asset.icon} name={asset.name} ticker={asset.ticker} size="largest" />

        <h1
          className="x__asset-page__header__heading"
          dangerouslySetInnerHTML={{ __html: data.h1 }}
        />
        <h2
          className="x__asset-page__header__subheading"
          dangerouslySetInnerHTML={{ __html: data.h2 }}
        />

        {DOWNLOAD_LINKS_ENABLED && (
          <div className="x__asset-page__header__actions">
            {asset.desktopWallet && (
              <PlatformDropdown platform={platforms.desktop} {...downloadDropdownProps} />
            )}
            {asset.mobileWallet && (
              <PlatformDropdown
                platform={platforms.mobile}
                androidLinkProps={googleDownloadProps}
                {...downloadDropdownProps}
              />
            )}
            {asset.web3Wallet && (
              <PlatformDropdown
                platform={platforms.web3}
                extensionLinkProps={googleDownloadProps}
                {...downloadDropdownProps}
              />
            )}
          </div>
        )}
      </div>
    </header>
  )
}

export default forwardRef(HeaderSection)
