import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { get } from 'lodash'
import millify from 'millify'

import Graph from 'src/components/assets/Chart/Graph'

import { fetchHistoricalPrices } from 'src/js/assets/fetchPrice'
import formatCurrency from 'src/js/utils/formatCurrency'

import { CURRENCY } from 'src/constants'

import 'static/components/stats/scss/styles.scss'

const PRICE_PLACEHOLDER = '...'

const AssetChart = ({ asset, colorStart, colorStop }) => {
  const [price, setPrice] = useState(PRICE_PLACEHOLDER)
  const [percentageChange, setPercentageChange] = useState(0)
  const [percentageChangeStr, setPercentageChangeStr] = useState(PRICE_PLACEHOLDER)
  const [marketCap, setMarketCap] = useState(PRICE_PLACEHOLDER)
  const [twentyFourHourVolume, setTwentyFourHourVolume] = useState(PRICE_PLACEHOLDER)
  const [fiftyTwoWeekLow, setFiftyTwoWeekLow] = useState(PRICE_PLACEHOLDER)
  const [fiftyTwoWeekHigh, setFiftyTwoWeekHigh] = useState(PRICE_PLACEHOLDER)
  const [twentyFourHourLow, setTwentyFourHourLow] = useState(PRICE_PLACEHOLDER)
  const [twentyFourHourHigh, setTwentyFourHourHigh] = useState(PRICE_PLACEHOLDER)

  useEffect(() => {
    let isMounted = true

    if (asset.price.current && isMounted) {
      const formattedPrice = formatCurrency({ number: asset.price.current, currency: CURRENCY })
      setPrice(formattedPrice)
    }

    if (asset.price.c24h && asset.price.mc && asset.price.v24h && isMounted) {
      setPercentageChange(asset.price.c24h)
      setPercentageChangeStr(`${asset.price.c24h.toFixed(2)}%`)
      setMarketCap(asset.price.mc)
      setTwentyFourHourVolume(asset.price.v24h)
    }

    const fetchData = async () => {
      let yearPrices = await fetchHistoricalPrices({
        assets: [asset.ticker],
        granularity: 'day',
        limit: 365,
      })
      if (yearPrices.err)
        console.error('Failed to fetch historical: day price data:', yearPrices.err)
      yearPrices = get(yearPrices, [asset.ticker, CURRENCY])

      if (yearPrices && isMounted) {
        let yearLow = yearPrices[0].close
        let yearHigh = yearPrices[0].close
        yearPrices.forEach(({ close }) => {
          yearLow = Math.min(close, yearLow)
          yearHigh = Math.max(close, yearHigh)
        })

        const formattedYearLow = formatCurrency({ number: yearLow, currency: CURRENCY })
        const formattedYearHigh = formatCurrency({ number: yearHigh, currency: CURRENCY })
        setFiftyTwoWeekLow(formattedYearLow)
        setFiftyTwoWeekHigh(formattedYearHigh)
      }

      let dayPrices = await fetchHistoricalPrices({
        assets: [asset.ticker],
        granularity: 'hour',
        limit: 24,
      })
      if (dayPrices.err) console.error('Failed to fetch historical: day price data:', dayPrices.err)
      dayPrices = get(dayPrices, [asset.ticker, CURRENCY])

      if (dayPrices && isMounted) {
        let oneDayLow = dayPrices[0].close
        let oneDayHigh = dayPrices[0].close
        dayPrices.forEach(({ close }) => {
          oneDayLow = Math.min(close, oneDayLow)
          oneDayHigh = Math.max(close, oneDayHigh)
        })

        const formattedOneDayLow = formatCurrency({ number: oneDayLow, currency: CURRENCY })
        const formattedOneDayHigh = formatCurrency({ number: oneDayHigh, currency: CURRENCY })
        setTwentyFourHourLow(formattedOneDayLow)
        setTwentyFourHourHigh(formattedOneDayHigh)
      }
    }
    fetchData()

    return () => {
      isMounted = false
    }
  }, [asset, fetchHistoricalPrices])

  return (
    <div className="container no-gutters x-components-asset-page-stats__container">
      <div className="row no-gutters mt-5 x-components-asset-page-stats__container-row">
        <div className="x-components-asset-page-stats__currencyNameContainer x-components-asset-page-stats__price">
          <div className="x-components-asset-page-stats__currencyName">{price}</div>
        </div>

        <div className="x-components-asset-page-stats__currencyChangeContainer">
          <div
            className={classNames('x-components-asset-page-stats__change', {
              'x-components-asset-page-stats__changeGreen': percentageChange > 0,
              'x-components-asset-page-stats__changeRed': percentageChange < 0,
            })}
          >
            <span className="x-components-asset-page-stats__change-percent">
              {percentageChangeStr}
            </span>
          </div>
          <div className="x-components-asset-page-stats__currencySymbolSubText">24h change</div>
        </div>
      </div>

      {asset && (
        <div className="row no-gutters x-components-asset-page-stats__graph">
          <div className="col-12">
            <Graph
              fromSymbol={asset.ticker}
              toSymbol={CURRENCY}
              colorStart={colorStart}
              colorStop={colorStop}
            />
          </div>
        </div>
      )}

      <div className="row mt-5 x-components-asset-page-stats__bar">
        <div className="col-6 col-md-2 x-components-asset-page-stats__tags">
          <div className="x-components-asset-page-stats__label">Market Cap</div>
          <div className="x-components-asset-page-stats__stat">
            {millify(Number(marketCap) || 0)} {CURRENCY}
          </div>
        </div>
        <div className="col-6 col-md-2 x-components-asset-page-stats__tags">
          <div className="x-components-asset-page-stats__label">24hr Volume</div>
          <div className="x-components-asset-page-stats__stat">
            {millify(Number(twentyFourHourVolume) || 0)} {CURRENCY}
          </div>
        </div>
        <div className="col-6 col-md-2 x-components-asset-page-stats__tags">
          <div className="x-components-asset-page-stats__label">24hr Low</div>
          <div className="x-components-asset-page-stats__stat">{twentyFourHourLow}</div>
        </div>
        <div className="col-6 col-md-2 x-components-asset-page-stats__tags">
          <div className="x-components-asset-page-stats__label">24hr High</div>
          <div className="x-components-asset-page-stats__stat">{twentyFourHourHigh}</div>
        </div>
        <div className="col-6 col-md-2 x-components-asset-page-stats__tags">
          <div className="x-components-asset-page-stats__label">52 weeks Low</div>
          <div className="x-components-asset-page-stats__stat">{fiftyTwoWeekLow}</div>
        </div>
        <div className="col-6 col-md-2 x-components-asset-page-stats__tags">
          <div className="x-components-asset-page-stats__label">52 weeks High</div>
          <div className="x-components-asset-page-stats__stat">{fiftyTwoWeekHigh}</div>
        </div>
      </div>
    </div>
  )
}

export default AssetChart
