// dependencies.
import React from 'react'

const SummaryTrezor = ({ asset, colors, alt }) => {
  const [start, stop] = colors.gradient
  const white = '#FFFFFF'

  if (!start || !stop) return null

  const wrapperStyles = {
    display: 'block',
    position: 'relative',
    width: 'fit-content',
  }

  const svgStyles = {
    display: 'block',
    height: '100%',
    width: '100%',
  }

  return (
    <span style={wrapperStyles}>
      <svg viewBox="0 0 60 120" fill="none" style={svgStyles}>
        <title>{alt}</title>
        <path
          opacity={0.25}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 105V68.7931V105C11.7584 94.6636 6.95783 88.5444 5.59842 86.6425C3.5593 83.7896 0 77.5862 0 68.7931L0 19C0 16.7909 1.79086 15 4 15H56C58.2091 15 60 16.7909 60 19V68.7931C60 77.5862 56.4407 83.7896 54.4016 86.6425C53.0422 88.5444 48.2416 94.6636 40 105H20Z"
          fill="url(#gradient1)"
        />
        <g opacity={0.4}>
          <path d="M30.5 64.4517V63.9396H29.5V64.4517H30.5Z" fill={white} />
          <path d="M30.5 59.8428V58.8186H29.5V59.8428H30.5Z" fill={white} />
          <path d="M30.5 54.7218V53.6976H29.5V54.7218H30.5Z" fill={white} />
          <path d="M30.5 49.6008V48.5766H29.5V49.6008H30.5Z" fill={white} />
          <path d="M30.5 44.4799V43.4557H29.5V44.4799H30.5Z" fill={white} />
          <path d="M30.5 39.3589V38.3347H29.5V39.3589H30.5Z" fill={white} />
          <path d="M30.5 34.2379V33.2137H29.5V34.2379H30.5Z" fill={white} />
          <path d="M30.5 29.117V28.0928H29.5V29.117H30.5Z" fill={white} />
          <path d="M30.5 23.996V22.9718H29.5V23.996H30.5Z" fill={white} />
          <path d="M30.5 18.875V17.8508H29.5V18.875H30.5Z" fill={white} />
          <path d="M30.5 13.754V12.7298H29.5V13.754H30.5Z" fill={white} />
          <path d="M30.5 8.63307V7.60888H29.5V8.63307H30.5Z" fill={white} />
          <path d="M30.5 3.5121V3H29.5V3.5121H30.5Z" fill={white} />
          <path d="M30.5 117.452V116.915H29.5V117.452H30.5Z" fill={white} />
          <path d="M30.5 112.625V111.552H29.5V112.625H30.5Z" fill={white} />
          <path d="M30.5 107.262V106.19H29.5V107.262H30.5Z" fill={white} />
          <path d="M30.5 101.899V100.827H29.5V101.899H30.5Z" fill={white} />
          <path d="M30.5 96.5363V96H29.5V96.5363H30.5Z" fill={white} />
        </g>
        <path
          opacity={0.25}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30 25V65H5C4.44772 65 4 64.5523 4 64V26C4 25.4477 4.44772 25 5 25H30Z"
          fill="url(#gradient2)"
        />
        <path
          opacity={0.5}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M55 25C55.5523 25 56 25.4477 56 26V64C56 64.5523 55.5523 65 55 65H30V25H55Z"
          fill="url(#gradient3)"
        />
        <path d="M10 52H40V55H10V52Z" fill={start} />
        <g opacity={0.4} style={{ mixBlendMode: 'overlay' }}>
          <path d="M10 52H40V55H10V52Z" fill={white} />
        </g>
        <path d="M10 43H50V46H10V43Z" fill={start} />
        <path fillRule="evenodd" clipRule="evenodd" d="M10 34H50V37H10V34Z" fill={stop} />
        <path
          opacity={0.5}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.2446 77.5296H36V87.1125H35.9879L30.0002 90L24.0121 87.1125H24V77.5296H25.7562V76.1843C25.7562 73.8773 27.6607 72 30.001 72C32.3402 72 34.2446 73.8773 34.2446 76.1843V77.5296ZM33.5851 85.5188V79.7782H26.4149V85.5188L30.0002 87.2436L33.5851 85.5188ZM27.9334 76.1843V77.5296H32.0675V76.1843C32.0675 75.1165 31.1398 74.2477 30.001 74.2477C28.861 74.2477 27.9334 75.1165 27.9334 76.1843Z"
          fill="url(#gradient4)"
        />
        <defs>
          <linearGradient
            id="gradient1"
            x1={43.8667}
            y1={105.9}
            x2={-6.72854}
            y2={30.7512}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={white} stopOpacity={0.1} />
            <stop offset={1} stopColor={white} stopOpacity={0.5} />
          </linearGradient>
          <linearGradient
            id="gradient2"
            x1={22.7122}
            y1={65.4}
            x2={0.804294}
            y2={32.026}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={white} stopOpacity={0.1} />
            <stop offset={1} stopColor={white} stopOpacity={0.5} />
          </linearGradient>
          <linearGradient
            id="gradient3"
            x1={48.7122}
            y1={65.4}
            x2={26.8043}
            y2={32.026}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={white} stopOpacity={0.1} />
            <stop offset={1} stopColor={white} stopOpacity={0.5} />
          </linearGradient>
          <linearGradient
            id="gradient4"
            x1={27.3333}
            y1={72}
            x2={39.7599}
            y2={90.4033}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={white} stopOpacity={0.5} />
            <stop offset={1} stopColor={white} stopOpacity={0.1} />
          </linearGradient>
        </defs>
      </svg>
    </span>
  )
}

export default SummaryTrezor
