// dependencies.
import React, { forwardRef } from 'react'
// components.
import Chart from 'src/components/assets/Chart'

// Styles & Images:
import 'src/components/pages/asset-page/scss/ChartSection.scss'

// Main component:
const ChartSection = ({ asset, colors, data }, ref) => {
  const checkColorsOverride =
    colors && colors.gradient && colors.gradient.length > 0
      ? colors.gradient
      : asset.colors.gradient

  const [start, stop] = checkColorsOverride

  return (
    <section ref={ref} className="x__asset-page__chart">
      <div className="x__asset-page__chart__content">
        <Chart colorStart={start} colorStop={stop} asset={asset} />
      </div>
    </section>
  )
}

export default forwardRef(ChartSection)
