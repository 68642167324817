// dependencies.
import React, { forwardRef } from 'react'
// components.
import SummaryExchange from 'src/components/pages/asset-page/img/summaryExchange.js'
import SummarySend from 'src/components/pages/asset-page/img/summarySend.js'
import SummaryShield from 'src/components/pages/asset-page/img/summaryShield.js'
import SummaryTrezor from 'src/components/pages/asset-page/img/summaryTrezor.js'
import SummaryUser from 'src/components/pages/asset-page/img/summaryUser.js'

// Styles & Images:
import 'src/components/pages/asset-page/scss/SummarySection.scss'

const svgIcons = {
  send: SummarySend,
  swap: SummaryExchange,
  trezor: SummaryTrezor,
  security: SummaryShield,
  friendly: SummaryUser,
}

const svgIcon = (key, asset, colors, alt) => {
  const IconComponent = svgIcons[key]

  if (IconComponent) {
    const props = { asset, colors, alt }
    return <IconComponent {...props} />
  }

  return null
}

// Main component:
const SummarySection = ({ page, asset, colors, data }, ref) => {
  const checkColorsOverride =
    colors && colors.primary && colors.gradient && colors.gradient.length > 0
      ? colors
      : asset.colors

  const items = data && Object.keys(data)

  if (items.length <= 0) return null

  return (
    <section ref={ref} className="x__asset-page__summary">
      <div className="x__asset-page__summary__content">
        <ul className="x__asset-page__summary__list">
          {items.map((key) => {
            const { h2, copy } = data[key]
            const icon = svgIcon(key, asset, checkColorsOverride, h2)

            return (
              icon && (
                <li key={key} className="x__asset-page__summary__list-item">
                  {icon}
                  <h2 dangerouslySetInnerHTML={{ __html: h2 }} />
                  <p>{copy}</p>
                </li>
              )
            )
          })}
        </ul>
      </div>
    </section>
  )
}

export default forwardRef(SummarySection)
