// dependencies.
import React from 'react'

const SummaryShield = ({ asset, colors, alt }) => {
  const [start, stop] = colors.gradient
  const white = '#FFFFFF'

  if (!start || !stop) return null

  const wrapperStyles = {
    display: 'block',
    position: 'relative',
    width: 'fit-content',
  }

  const svgStyles = {
    display: 'block',
    height: '100%',
    width: '100%',
  }

  return (
    <span style={wrapperStyles}>
      <svg viewBox="0 0 64 120" fill="none" style={svgStyles}>
        <title>{alt}</title>
        <path
          opacity={0.25}
          d="M32.0007 22.0002L32 96.9991C31.9994 96.9994 31.9988 96.9997 31.9983 97C10.6958 86.0713 0.0309728 71.0546 0.00389703 51.9482C-0.00129901 48.2815 -0.00129901 42.2694 0.00389703 33.9117L32.0007 22.0002Z"
          fill="url(#gradient1)"
        />
        <path
          opacity={0.5}
          d="M32 28L5.00329 38.0059C4.9989 45.0263 4.9989 50.0765 5.00329 53.1565C5.02613 69.2059 14.0246 81.8199 31.9985 91C49.9745 81.8213 58.9739 69.2068 58.9967 53.1565C59.0011 50.0765 59.0011 45.0263 58.9967 38.0059L32 28Z"
          fill="url(#gradient2)"
        />
        <path d="M53.8087 30.1194L31.9988 51.9293L31.9991 22L53.8087 30.1194Z" fill={stop} />
        <path
          d="M31.9985 78.9296L63.6974 47.2307L63.9984 47.5317L63.9982 48.2142L63.9952 33.9117L53.8087 30.1194L31.9988 51.9293L31.9985 78.9296Z"
          fill={start}
        />
        <path
          d="M63.9984 47.5317L63.9951 51.9482C63.968 71.0554 53.3024 86.0725 31.9983 96.9995L31.9985 78.9296L63.6974 47.2307L63.9984 47.5317Z"
          fill={start}
        />
        <g opacity={0.4} style={{ mixBlendMode: 'overlay' }}>
          <path
            d="M63.9984 47.5317L63.9951 51.9482C63.968 71.0554 53.3024 86.0725 31.9983 96.9995L31.9985 78.9296L63.6974 47.2307L63.9984 47.5317Z"
            fill={white}
          />
        </g>
        <path
          d="M31.9983 90.9996L31.9989 28L58.9957 38.0058C59.0001 45.0263 59.0001 50.0765 58.9957 53.1565C58.9728 69.2065 49.9737 81.8209 31.9983 90.9996Z"
          fill="url(#gradient3)"
        />
        <path
          opacity={0.4}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.4993 5.5V5H32.4993V5.5H31.4993ZM31.4993 10.5V9.5H32.4993V10.5H31.4993ZM31.4993 15.5V14.5H32.4993V15.5H31.4993ZM31.4993 20.5V19.5H32.4993V20.5H31.4993ZM31.4993 25.5V24.5H32.4993V25.5H31.4993ZM31.4993 30.5V29.5H32.4993V30.5H31.4993ZM31.4993 35.5V34.5H32.4993V35.5H31.4993ZM31.4993 40.5V39.5H32.4993V40.5H31.4993ZM31.4993 45.5V44.5H32.4993V45.5H31.4993ZM31.4993 50.5V49.5H32.4993V50.5H31.4993ZM31.4993 55.5V54.5H32.4993V55.5H31.4993ZM31.4993 60.5V59.5H32.4993V60.5H31.4993ZM31.4993 65.5V64.5H32.4993V65.5H31.4993ZM31.4993 70.5V69.5H32.4993V70.5H31.4993ZM31.4993 75.5V74.5H32.4993V75.5H31.4993ZM31.4993 80.5V79.5H32.4993V80.5H31.4993ZM31.4993 85.5V84.5H32.4993V85.5H31.4993ZM31.4993 90.5V89.5H32.4993V90.5H31.4993ZM31.4993 95.5V94.5H32.4993V95.5H31.4993ZM31.4993 100.5V99.5H32.4993V100.5H31.4993ZM31.4993 105.5V104.5H32.4993V105.5H31.4993ZM31.4993 110.5V109.5H32.4993V110.5H31.4993ZM31.4993 115V114.5H32.4993V115H31.4993Z"
          fill={white}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.9969 69.451L21.2805 59.7346L24.5457 56.4695L31.0223 62.947L42.4514 51.519L45.6902 54.7578L30.9969 69.451Z"
          fill={white}
        />
        <defs>
          <linearGradient
            id="gradient1"
            x1={8.69831}
            y1={22}
            x2={66.4162}
            y2={88.975}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={white} stopOpacity={0.1} />
            <stop offset={1} stopColor={white} stopOpacity={0.5} />
          </linearGradient>
          <linearGradient
            id="gradient2"
            x1={52.6302}
            y1={91.63}
            x2={5.11043}
            y2={36.7338}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={white} stopOpacity={0.1} />
            <stop offset={1} stopColor={white} stopOpacity={0.5} />
          </linearGradient>
          <linearGradient
            id="gradient3"
            x1={31.999}
            y1={28}
            x2={31.999}
            y2={91}
            gradientUnits="userSpaceOnUse"
          >
            <stop stopOpacity={0.15} />
            <stop offset={1} stopColor={white} stopOpacity={0} />
          </linearGradient>
        </defs>
      </svg>
    </span>
  )
}

export default SummaryShield
